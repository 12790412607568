import React, {  useEffect, useRef, useState,  } from "react";
import Time from "../../components/time";
import {ReactComponent as Man } from "../../assets/images/man.svg"
import {ReactComponent as Woman } from "../../assets/images/woman.svg"
import {ReactComponent as Welcome } from "../../assets/images/itinerario/welcome.svg"
import {ReactComponent as Ceremony } from "../../assets/images/itinerario/ceremony.svg"
import {ReactComponent as Dance } from "../../assets/images/itinerario/dance.svg"
import {ReactComponent as Toast } from "../../assets/images/itinerario/toast.svg"
import {ReactComponent as Party } from "../../assets/images/itinerario/party.svg"
import {ReactComponent as Party2 } from "../../assets/images/itinerario/party2.svg"
import {ReactComponent as CrazyHour } from "../../assets/images/itinerario/crazyHour.svg"
import {ReactComponent as Camera } from "../../assets/images/itinerario/camera.svg"
import {ReactComponent as End } from "../../assets/images/itinerario/end.svg"
import {ReactComponent as Music } from "../../assets/images/music.svg"
import {ReactComponent as Location } from "../../assets/images/location.svg"
import {ReactComponent as Loco } from "../../assets/images/logo.svg"
import grammas from "../../assets/images/grammas.png"
import maik from "../../assets/images/maik.png"
import Itinerary from "../../components/itinerary";
import ModalBackground from "../../components/modal/modal_background";
import Modal from "../../components/modal/modal";
import useModal from "../../hooks/useModal"
import ModalMusic from "../../components/modal/modal_music";
import AnimationHome from "../../components/animation";
import { USER_ENDPOINTS } from "../../services/api.config";
import post from "../../helpers/post";
import { useLocation } from "react-router-dom";
import get from "../../helpers/get";
import ModalConfig from "../../components/modal/modal_config";


const preprocessTableBodyData=(data)=>{
  console.log(data)
  data=data || []
  const actionButtons=(id,status)=>{
    let check = false
    if(status===2){
      check = true
    }
    console.log(check)
    return (
      <div className="flex justify-center">
        <input id={"name"+id} value={id} type="checkbox" class=" hidden peer consulta12" defaultChecked={check}/>
        <label for={"name"+id} className="w-5 h-5 flex bg-[#FFD5D5] rounded-full peer-checked:bg-[#50FF6C] peer-checked:before:opacity-100 before:pl-1 before:opacity-0 before:content-[''] before:w-[10px] before:h-[5px] before:border-b-2 before:border-l-2 before:border-white before:flex before:m-auto before:mt-[7px] before:rotate-[315deg]"></label>
      </div>
    )
  }
  const htmlName=(message,name)=>{
      var use = false
      if(message.length > 0){
          use = true
      }
      return (
        <div className="flex">
            <p className=" mx-4 font-poppins text-sm">{name}</p>
        </div>
      )
  }
  let _processedData=[...data].map(row=>{
      let {id,name, status}=row
          let _newData={
              Name: htmlName(id,name),
              actions:actionButtons(id,status)
          }
          return _newData
  })
  return _processedData
      
}

const Home = () => {
  let [isActiveModal,activateModal,desactivateModal]=useModal(false)
  let [isActiveModalMusic,activateModalMusic,desactivateModalMusic]=useModal(false)
  let [isActiveModalConfir,activateModalConfir,desactivateModalConfir]=useModal(false)
  let [title, isTitle] = useState('')
  let [message, isMessage] = useState('')
  let [music, isMusic] = useState('')
  let [divMainClassName, isDivMainClassName] = useState("h-[100vh] overflow-hidden")
  let [logoClassName, IsLogoClassName] = useState('w-7/12 mx-auto mt-0')
  let [timeClassName, isTimeClassName] = useState('mt-24')
  let [sizeClassName, isSizeClassName] = useState('150vw')
  let [link, setLink] = useState()
  let [guest, setGuest] = useState()
  let [id, setId] = useState()
  let [black, setBlack] = useState(false)
  let [processedClients,setProcessedClients]=useState(null)
  let $name = useRef()
  const location = useLocation();
  
  const GuestOne = ()=>{
    const queryGuest = new URLSearchParams(location.search).get('link')
    if(queryGuest){
      get(USER_ENDPOINTS.getOne(queryGuest)).then(r =>{
        if(r.message === 'all is fine'){
          setBlack(true)
          setId(r.data.id)
          setGuest(r.data.guest)
          setLink(queryGuest)
        }
        if(r.message==='error does not exist'){
          setBlack(false)
        }
      })
    }
  }

  let m1 = 'Si, toma tus previsiones para que llegues a tiempo, sólo se esperará a la novia para iniciar la ceremonia.'
  let m2 = 'La boda durará hasta las 2 de la mañana aproximadamente.'
  let m3 = 'Solo podrán asistir los niños que fueron invitados específicamente.'
  let m4 = 'No, sólo las personas invitadas por los novios podrán asistir.'
  let m5 = 'Queremos que todos disfrutemos  sin tener que preocuparnos por nada así que si planeas tomar lleva chofer designado o entrega las llaves del carro.'

  const question = (Title, Message) =>{
    isTitle(Title)
    isMessage(Message)
    activateModal()
  }
  const handleSave = () => {
    if(music.length > 0 ){
      let _data={
        name : music,
      }
      let _createClientPromise=post(USER_ENDPOINTS.createMusic(),_data)
      _createClientPromise.then(response=>{
          if(response.message==="all is fine"){
            desactivateModalMusic()
            $name.current.value = ''
          }
      })
    }
  }
  useEffect(()=>{
    let _processedData=preprocessTableBodyData(guest)
    setProcessedClients(_processedData) 
  },[guest])
  useEffect(() => {
    GuestOne()
  }, []);
  return (
    <>
    {black?
      <div className={`${divMainClassName}`}>
        <AnimationHome isDivMainClassName={isDivMainClassName} IsLogoClassName={IsLogoClassName} isTimeClassName={isTimeClassName} isSizeClassName={isSizeClassName}/>
        <section className="bg-[url('/src/assets/images/bg/bg-main.jpg')] bg-cover bg-center w-full h-[80vh] flex flex-col mb-6"  style={{transitionDuration: '1300ms', transitionProperty: 'all', backgroundSize : sizeClassName}}>
          <Loco className={logoClassName} style={{transitionDuration: '1300ms', transitionProperty: 'all'}}/>
          <div className="h-1/6 mt-auto w-full bg-gradient-to-t from-white"></div>
        </section>
        <div className={timeClassName} style={{transitionDuration: '1300ms', transitionProperty: 'all'}}>
          <Time/>
        </div>
        <section className="mt-4 w-5/6 m-auto">
          <p className=" text-center text-primary font-poppins my-6 text-sm">¡Nos casamos!</p>
          <p className=" text-center text-primary font-poppins my-6 text-sm">Después de compartir experiencias y de descubrir mil cosas juntos, comenzamos una nueva etapa y no sería posible celebrar nuestra boda sin la compañía de nuestra familia y nuestros mejores amigos.</p>
        </section>
        <section className="mt-14 flex flex-col">
          <h2 className="text-center font-poppinsBold text-primary text-sm">Nuestra boda será el día</h2>
          <section className="flex m-auto w-[80vw] mt-3">
            <p className="text-primary font-poppins text-sm py-2 border-y-2 border-y-primary w-1/3 text-center h-fit mt-auto">Sábado</p>
            <div className="w-1/3 flex flex-col">
              <p className="text-primary font-poppins text-sm text-center">Marzo</p>
              <h4 className="text-4xl font-poppinsBold inline-block text-primary m-auto">23</h4>
            </div>
            <p className="text-primary font-poppins text-sm py-2 border-y-2 border-y-primary w-1/3 text-center h-fit mt-auto">2024</p>
          </section>
        </section>
        <section className="mt-14 flex flex-col">
          <h2 className="text-xl font-poppinsBold inline-bloc text-textColor m-auto">Código de vestimenta</h2>
          <section className="flex w-10/12 m-auto mt-6">
            <Man className="w-1/4"/>
            <Woman className="w-1/4"/>
            <div className="flex flex-col m-auto">
              <p className="w-fit mx-auto font-poppinsBold text-primary text-sm px-4 py-2 ">Formal</p>
              <a className="w-fit mx-auto font-poppinsBold text-white text-sm bg-primary px-4 py-2 rounded-full" target="_blank" href="https://pin.it/2kWmTnj">Sugerencias</a>
            </div>
          </section>
          <h3 className="w-fit mx-auto font-poppinsBold text-textColor text-sm mt-10">¿Qué colores no usar?</h3>
          <section className="w-11/12 m-auto mt-6 flex">
            <div className="flex w-8/12 justify-between">
              <div className="flex w-10 h-10 rounded-full bg-[#636D48] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
              <div className="flex w-10 h-10 rounded-full bg-[#96A77D] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
              <div className="flex w-10 h-10 rounded-full bg-[#DDECCB] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
              <div className="flex w-10 h-10 rounded-full bg-[#5e2129] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
              <div className="flex w-10 h-10 rounded-full border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
            </div>
            <p className="w-fit font-poppinsBold text-textColor text-sm my-auto ml-4">Mujeres</p>
          </section>
          <section className="w-11/12 m-auto mt-6 flex">
            <div className="flex w-8/12 justify-center">
              <div className="flex w-10 h-10 rounded-full bg-[#E1C699] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
            </div>
            <p className="w-fit font-poppinsBold text-textColor text-sm my-auto ml-4">Hombres</p>
          </section>
          <p className=" text-center text-textColor font-poppins my-6 text-sm">Ten en cuenta que estaremos en un <span className="font-poppinsBold">jardín</span><br/>y en su mayoría es <span className="font-poppinsBold">grama.</span></p>
          <img src={grammas} className="w-[110vw] object-center"/>
        </section>
        <section className="mt-4 flex flex-col">
          <h2 className="text-xl font-poppinsBold inline-bloc text-textColor m-auto">Itinerario</h2>
          <p className=" text-center text-textColor font-poppins text-sm mt-2">¡Iniciamos a las 4:30 p.m!</p>
          <Itinerary Svg={Welcome} Text='Recepción'/>
          <Itinerary Svg={Ceremony} Text='Ceremonia'/>
          <Itinerary Svg={Toast} Text='Brindis'/>
          <Itinerary Svg={Camera} Text='Fotos'/>
          <Itinerary Svg={Dance} Text='Baile de novios'/>
          <Itinerary Svg={Party} Text='Party'/>
          <Itinerary Svg={Party2} Text='Más party'/>
          <Itinerary Svg={CrazyHour} Text='Hora loca'/>
          <Itinerary Svg={End} Text='Se acabó'/>
          <p className="text-center text-textColor font-poppins mt-14 text-sm w-7/12 m-auto">Sugiérenos qué canciones no podrían faltar en la boda</p>
          <div className="flex w-fit mx-auto mt-2 cursor-pointer" href="#/" onClick={activateModalMusic}>
            <div className="my-auto w-12 h-12 flex bg-primary rounded-full mr-[-1rem] box-shadow-music z-10">
              <Music className=" w-9/12 h-9/12 m-auto"/>
            </div>
            <p className="w-fit my-auto font-poppinsBold text-white text-sm bg-primary px-4 py-2 rounded-tr-full rounded-br-full">Sugerir canciones</p>
          </div>
        </section>
        <section className="mt-10 mb-4 flex flex-col">
          <h2 className="text-xl font-poppinsBold inline-bloc text-textColor m-auto">¿Preguntas?</h2>
          <p className="text-center text-textColor font-poppinsBold mt-4 text-sm w-fit m-auto cursor-pointer" href="#/" onClick={()=>{question('¿Seremos puntuales?', m1)}}>¿Seremos puntuales?</p>
          <p className="text-center text-textColor font-poppinsBold mt-4 text-sm w-fit m-auto cursor-pointer" href="#/" onClick={()=>{question('¿Hasta que hora será evento?', m2)}}>¿Hasta que hora será evento?</p>
          <p className="text-center text-textColor font-poppinsBold mt-4 text-sm w-fit m-auto cursor-pointer" href="#/" onClick={()=>{question('¿Se pueden llevar niños?', m3)}}>¿Se pueden llevar niños?</p>
          <p className="text-center text-textColor font-poppinsBold mt-4 text-sm w-fit m-auto cursor-pointer" href="#/" onClick={()=>{question('¿Puedo llevar invitados?', m4)}}>¿Puedo llevar invitados?</p>
          <p className="text-center text-textColor font-poppinsBold mt-4 text-sm w-fit m-auto cursor-pointer" href="#/" onClick={()=>{question('¿Planeas beber?', m5)}}>¿Planeas beber?</p>
        </section>
        <section className="bg-[url('/src/assets/images/bg/bg-end.jpg')] bg-cover bg-center w-full flex flex-col bg-opacity-70">
          <div className="mt-auto w-full bg-gradient-to-b from-white flex h-[9rem]">
            <a className="m-auto flex" target="_blank"  href="https://maps.app.goo.gl/4TwS56FjG4oFZyji8">
              <Location className="w-10 h-10 fill-textColor"/>
              <p className="text-center text-textColor font-poppinsBold ml-2 text-sm w-fit my-auto">Estancia Rancho Chico</p>
            </a>
          </div>
          <p className="text-center text-white font-poppinsBold mt-1 text-sm w-9/12 mx-auto">
            Es muy importante para nosotros saber si podrás asistir a nuestra boda<br/><br/>
            Si asistirás no olvides confirmar
          </p>
          <p className="w-fit mx-auto font-poppinsBold text-white text-sm bg-primary px-12 py-3 rounded-full my-8 cursor-pointer" onClick={activateModalConfir}>Confirma tu asistencia aquí</p>
          <a href="https://www.instagram.com/maik_dev?igsh=ZjI1amx1NmxlajNt" target="_blank" className="opacity-80 text-xs text-white w-fit mx-auto my-2">Desarrollado por MAIK © 2023</a>
        </section>
        <ModalBackground isActive={isActiveModal} closeByBackground={desactivateModal}>
          <Modal title={title} message={message} closeByButton={desactivateModal} handleCancelation={desactivateModal}/>
        </ModalBackground>
        <ModalBackground isActive={isActiveModalMusic} closeByBackground={desactivateModalMusic}>
          <ModalMusic handleSave={handleSave} handleText={isMusic} $name={$name}/>
        </ModalBackground>
        <ModalBackground isActive={isActiveModalConfir} closeByBackground={desactivateModalConfir}>
          <ModalConfig link={id} data={processedClients} handleCancelation={desactivateModalConfir} updata={GuestOne}/>
        </ModalBackground>
      </div>
    :''}
    </>
    
  );
}

export default Home;

