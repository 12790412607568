
import React from 'react'
import { createPortal } from 'react-dom'

const ACTIVE_MODAL_BACKGROUND_STYLES={
    display:"flex"
}
const INACTIVE_MODAL_BACKGROUND_STYLES={
    display:"none"
}

const ModalBackground =({children,isActive,closeByBackground}) => {
  return createPortal(
    <div style={isActive?ACTIVE_MODAL_BACKGROUND_STYLES:INACTIVE_MODAL_BACKGROUND_STYLES} onClick={closeByBackground}>  
        {children}
    </div>,
    document.getElementById("modal")
  )
}

export default ModalBackground
