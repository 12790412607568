import React from 'react'

const SaveButton=({handle,evento})=>{

    return (
        <button onClick={handle} className='mr-4 px-10 py-3 rounded-xl bg-green font-bold'  style={{pointerEvents : evento}} >
            Confirmar
        </button>
    )
}
const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='ml-4 text-textColor font-bold'>
            Cancelar
        </button>
    )
}
const ModalEdict = ({handleSave,handleCancel, handleText, $name,title}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <>
        <div className='m-auto w-fit h-fit bg-white py-12 px-16 rounded-3xl relative' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-montserrat font-bold text-3xl mb-8'>{title}</h3>
                <input type="text" placeholder="Invitado 1 (Obligatorio)" ref={$name} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80 prueba1000" onChange={(e)=>{handleText(e.target.value)}}/>
            </div>
            <div className='flex justify-around my-5'>
                <CancelButton handle={handleCancel}/>
                <SaveButton handle={handleSave}/>
            </div>
        </div>
        </>
    )
}

export default ModalEdict