import React from 'react'
import put from '../../helpers/put'
import { USER_ENDPOINTS } from '../../services/api.config'

const SaveButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-secondary font-poppinsBold text-sm'>
            Confirmar
        </button>
    )
}


const ModalConfig = ({link, data, handleCancelation,updata}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    const handleCreater = ()=>{
        let invitado = []
        let inputData = document.getElementsByClassName("consulta12")
        if(inputData){
            for(let el of inputData) {
                if(el.value.length > 0 ){
                    let obj = {
                        id : Number(el.value),
                        status: el.checked?2:1
                    }
                    invitado.push(obj)
                }
            }
            let _createClientPromise=put(USER_ENDPOINTS.updateStatus(link),invitado)
            _createClientPromise.then(response=>{
                if(response.message==="all is fine"){
                    handleCancelation()
                }
            })
        }
    }
    return (
        <div className='m-auto w-10/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-poppinsBold text-sm mt-6 text-textColor'>Grupo de invitados</h3>
                <p className='mx-4 my-2 font-poppins text-sm  text-textColor'>Perteneces a este grupo de invitados.<br/>Tilda tu nombre <span>si</span> asistirás.</p>
                <div className=" max-h-[50vh] overflow-auto my-6">
                {data?
                    data.map((row,index)=>{
                        return (
                            <div key={index} style={{gridTemplateColumns:'80% 20%', backgroundColor: index % 2 === 0 ? '#fff' : '#fbfbfb'}}  className='grid h-fit py-3' >
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell text-ellipsis text-jn px-3' >
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :""
                }
                </div>
            </div>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <SaveButton handle={handleCreater}/>
            </div>
        </div>
    )
}

export default ModalConfig