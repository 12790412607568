import React from 'react'

const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-secondary font-poppinsBold text-sm'>
            Cerrar
        </button>
    )
}

const Modal = ({title,message,handleCancelation}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <div className='m-auto w-10/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-poppinsBold text-sm mt-6 text-textColor'>{title}</h3>
                <p className='font-poppinsd text-center text-sm px-4 mb-6 mt-4 text-textColor'>{message}</p>
            </div>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <CancelButton handle={handleCancelation}/>
            </div>
        </div>
    )
}

export default Modal