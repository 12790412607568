// COMPONETISAR LAS TABLAS
// RECIBEN LOS HEADS
// RECIBEN LAS FILAS DE DATOS
// RECIBEN LOS BOTONES DE ACCIONES DEL FOOTER

import React from 'react'

const dynamicColumns=(columnsNumber)=>{
    return {
        "--columns":columnsNumber
    }
}

const Table = ({head,body,error,columns, className}) => {
    return (
    <div className='w-full'>
        <section style={{gridTemplateColumns:columns}} className={className}>
                {head.map((each,index)=>{
                    return (
                        <span className='table-head-cell' key={index}>{each}</span>
                    )
                })}
        </section>
        <section className='w-11/12 bg-white mx-auto flex flex-col rounded-b-2xl h-[35vw] border-t-2 border-solid border-textColor/50 overflow-auto scroll-w-none'>
            {
                body?
                    body.map((row,index)=>{
                        return (
                            <div key={index} style={{gridTemplateColumns:columns, backgroundColor: index % 2 === 0 ? '#fff' : '#f8f8f8'}}  className='grid h-fit py-4' >
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell text-ellipsis text-jn px-3' >
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :
                error? <><p>{error.message}</p> </>:<> <p>No hay datos para mostrar</p></>
                }
        </section>
    </div>)
}

export default Table