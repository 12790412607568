import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { USER_ENDPOINTS } from '../../services/api.config'



const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='mx-auto px-auto py-3 text-textColor font-bold'>
            Cancelar
        </button>
    )
}
const preprocessTableBodyData=(data)=>{
    data=data || []
    const htmlName=(name)=>{
        return (
            <>
                <div className="flex justify-center">
                    <p className="font-bold cursor-pointer">{name}</p>
                </div>
            </>
        )
    }
    let _processedData=[...data].map(row=>{
        let {name}=row
        console.log(name)
            let _newData={
                Name: htmlName(name),
            }
            return _newData
    })
    return _processedData
        
}

const ModalMusicView = ({handleCancelation}) => {
    let {data:fetchedMusic,loading:loadingFetchedMusic,error:fetchedMusicErrors}=useFetch(USER_ENDPOINTS.getAllMusic())
    let [music, setMusic] = useState()
    
    useEffect(()=>{
        if(fetchedMusic)setMusic(preprocessTableBodyData(fetchedMusic.data))
    },[fetchedMusic])
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <div className='m-auto w-2/4 h-fit bg-white py-10 rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-montserrat font-bold text-3xl'>Canciones</h3>
            </div>
            <div className='h-[25vh] mx-8 overflow-auto mt-6 mb-2'>
            {
                music? music.map((row,index)=>{
                        return (
                            <div key={index} style={{backgroundColor: index % 2 === 0 ? '#fff' : '#f8f8f8'}}  className='grid h-fit py-4' >
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell text-ellipsis text-jn px-3' >
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :""
                }
            </div>
            <div className='flex mt-5 mx-10'>
                <CancelButton handle={handleCancelation}/>
            </div>
        </div>
  )
}

export default ModalMusicView