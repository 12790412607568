import { UserAuthContext } from "../../contexts/authContext";
import get from '../../helpers/get';
import { LOGIN_ENDPOINTS } from "../../services/api.config";

const Logout = () => {
    const {logout} = UserAuthContext();
    get(LOGIN_ENDPOINTS.logout()).then(r =>{
        console.log(r.message)
        if(r.message === 'all is fine'){
            logout()
        }
    })
    return null
}
export default Logout