import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './page/Home/home';
import {  HOME, LOGIN, LOGOUT, USER, USEREDIT } from './routes/paths';
import User from './page/User/user';
import UserHome from './page/userHome/UserHome';
import Logout from './page/logout/logout';
import PublicRouter from './components/router/publicRouter';
import PrivateRouter from './components/router/privateRouter';
import UserFamily from './page/userFamily/UserFamily';

function App() {
  return (
    <Routes>
      <Route path={HOME} element={<Home/>} />
      <Route path={LOGIN} element={<PublicRouter/>}>
        <Route index path={LOGIN} element={<User/>} />
      </Route>
      <Route path={USER} element={<PrivateRouter/>}>
        <Route path={USER} element={<UserHome/>}/>
        <Route path={USEREDIT} element={<UserFamily/>}/>
        <Route path={LOGOUT} element={<Logout/>} />
      </Route>
    </Routes>
  );
}

export default App;
