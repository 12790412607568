import { useEffect, useRef, useState } from "react";
import Li from "./li";

const Time = ()=>{
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [endDate, setEndDate] = useState(new Date("March 23, 2024 16:30:00").getTime());
  const intervalRef = useRef();

  const getTimeRemaining = () => {
    const total = endDate - new Date().getTime();
    if(total>0){
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
      setDays(days.toString().padStart(2, '0'));
      setHours(hours.toString().padStart(2, '0'));  
      setMinutes(minutes.toString().padStart(2, '0'));
    }else{
      setDays('00');
      setHours('00');
      setMinutes('00');
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      getTimeRemaining();
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  return(
    <div className="w-full flex flex-col">
      <h3 className=" text-primary m-auto leading-normal text-xl mx-auto w-fit">Faltan</h3>
      <ul className="flex flex-row mx-auto py-4">
        <Li text="días" textTime={days}/>
        <p className="text-4xl font-poppinsBold inline-block text-primary mx-auto">:</p>
        <Li text="horas" textTime={hours}/>
        <p className="text-4xl font-poppinsBold inline-block text-primary mx-auto">:</p>
        <Li text="minutos" textTime={minutes}/>
        {/* <Li text="seg" textTime={seconds} bg="bg-primary-m700"/> */}
      </ul>
    </div>
  )
}

export default Time