const Itinerary = ({Svg, Text}) => {
    return(
        <section className="flex flex-col mt-8">
            <div className="m-auto mt-4 w-20 h-20 flex bg-[#EDEDED] rounded-full">
                <Svg className=" w-7/12 h-auto m-auto fill-primary"/>
            </div>
            <h4 className="text-center font-poppinsBold text-primary text-sm mt-2">{Text}</h4>
        </section>
    )
}

export default Itinerary