import { useEffect, useState } from "react";

const AnimationHome = ({isDivMainClassName,IsLogoClassName,isTimeClassName,isSizeClassName})=>{
    let [className, isClassName] = useState('opacity-0 mt-[40vh] font-poppinsBold text-xl')
    let [divClassName, isDivClassName] = useState('bg-secondary fixed w-full h-full z-20 flex')
    let [svgClassName, isSvgClassName] = useState('hidden mx-auto opacity-0')
    let [path1ClassName, isPath1ClassName] = useState('scale-x-50')
    let [path2ClassName, isPath2ClassName] = useState('scale-y-75')
    
    const FuntionOne = () =>{
        setTimeout(() => {
            isClassName('m-0 font-poppinsBold text-xl')
            setTimeout(() => {
                isClassName('opacity-0 mt-[40vh] font-poppinsBold text-xl')
                isDivClassName('bg-white fixed w-full h-full z-20 flex opacity-100')
                setTimeout(()=>{
                isClassName('hidden')
                isSvgClassName('flex mx-auto opacity-0')
                setTimeout(()=>{
                    isSvgClassName('mx-auto opacity-100')
                    isPath1ClassName('scale-x-100')
                    isPath2ClassName('scale-y-100')
                    setTimeout(()=>{
                    isSvgClassName('mx-auto opacity-0 mt-[-40vh]')
                    setTimeout(()=>{
                        isDivClassName('bg-white fixed w-full h-full z-20 flex opacity-0')
                        IsLogoClassName('w-7/12 mx-auto mt-20')
                        isTimeClassName('mt-0')
                        isSizeClassName('115vw')
                        setTimeout(()=>{
                        isDivClassName('hidden')
                        isDivMainClassName('')
                        }, 1500)
                    }, 1000)
                    }, 2500)
                }, 20)
                }, 1200)
            }, 3500);
        }, 300);
    } 
    useEffect(() => {
        FuntionOne()
    }, []);
    return(
        <div className={divClassName} style={{transitionDuration: '2000ms', transitionProperty: 'all'}}>
            <div className="w-fit h-fit m-auto">
            <h2 className={className} style={{transitionDuration: '1200ms', transitionProperty: 'all', color: '#fff', textAlign: 'center'}}>¡Es un placer para nosotros<br/>invitarte a nuestra boda!</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="205" height="66" viewBox="0 0 205 66" fill="none" className={svgClassName} style={{transitionDuration: '1500ms', transitionProperty: 'all'}} >
                <g clip-path="url(#clip0_13_144)">
                <path d="M0 36.2165V30.3708H204.998V36.2165H0Z" fill="#E7B68E"  className={path1ClassName} style={{transitionDuration: '2000ms', transitionProperty: 'all'}}/>
                <path d="M92.7161 44.9214V42.0234C97.1985 42.0234 101.624 41.8549 106.016 42.0838C108.742 42.2263 110.459 44.1548 111.056 46.8519C111.565 49.1518 110.602 51.2486 108.33 52.7948C108.171 52.9028 108.021 53.0108 107.678 53.2677C110.526 54.0689 112.006 55.8785 112.184 58.6664C112.423 62.4131 110.044 65.3608 106.074 65.6048C101.668 65.8726 97.2311 65.6674 92.7248 65.6674V51.9764C95.9556 51.9764 99.1931 51.998 102.43 51.9634C103.27 51.9553 104.107 51.8509 104.923 51.6524C106.863 51.1666 107.662 49.8277 107.406 47.6704C107.208 46.0054 105.989 44.9667 104.069 44.9451C100.914 44.9127 97.7677 44.9343 94.6064 44.9343L92.7161 44.9214ZM96.4793 62.9098C99.6385 62.7586 102.635 62.791 105.577 62.4131C107.654 62.1453 108.51 60.6855 108.423 58.6319C108.338 56.6689 107.384 55.3387 105.346 55.1918C102.437 54.9759 99.5038 55.1378 96.4793 55.1378V62.9098Z" fill="#E7B68E"/>
                <path d="M146.95 16.2824H150.225C152.026 20.8843 154.994 22.329 159.741 20.9253C161.525 20.3984 162.607 19.2927 162.651 17.9495C162.694 16.5459 161.664 15.1919 159.954 14.6024C157.386 13.717 154.783 12.9331 152.217 12.0434C151.32 11.7324 150.39 11.3632 149.651 10.7952C149.005 10.3039 148.476 9.67574 148.104 8.95624C147.733 8.23674 147.527 7.44398 147.501 6.63552C147.476 5.82705 147.633 5.02315 147.959 4.28218C148.285 3.54121 148.773 2.88174 149.388 2.35167C151.728 0.276413 154.603 -0.120932 157.599 0.0280712C158.344 0.0707479 159.083 0.182081 159.807 0.360632C163.348 1.20067 165.139 3.31695 165.649 7.35085H162.234C160.791 3.44004 156.967 1.8809 153.006 3.61928C151.869 4.11596 150.996 5.00566 151.218 6.16314C151.402 7.12626 152.074 8.34637 152.889 8.76962C154.651 9.68092 156.643 10.1538 158.538 10.8103C159.691 11.2098 160.856 11.5856 161.988 12.0347C164.793 13.1469 166.151 14.9932 166.164 17.6299C166.179 20.5668 164.943 22.4218 161.99 23.5469C158.549 24.8942 154.713 24.8383 151.313 23.3914C148.547 22.2469 146.892 19.4849 146.95 16.2824Z" fill="#E7B68E"/>
                <path d="M160.589 65.6782C160.745 65.5875 160.706 65.6307 160.667 65.6307C156.348 65.6782 156.849 66.4492 155.974 60.9792C155.359 57.1354 154.629 56.4184 150.746 56.4206C148.982 56.4206 147.218 56.4278 145.455 56.4422C145.29 56.4711 145.129 56.5166 144.973 56.5782V65.5854H141.247V53.2634H144.832C147.237 53.2634 149.642 53.3347 152.045 53.2634C154.977 53.1814 156.304 51.6956 156.267 48.7091C156.239 46.2646 154.759 44.9473 151.961 44.9322C148.439 44.9127 144.914 44.9322 141.271 44.9322V42.0881C145.812 42.0881 150.342 41.8722 154.835 42.168C157.76 42.3667 159.759 44.8156 159.967 47.8237C160.185 50.9118 159.372 53.3088 155.926 54.7513C158.992 55.5654 159.311 57.956 159.665 60.3659C159.922 62.1086 160.267 63.8492 160.589 65.6782Z" fill="#E7B68E"/>
                <path d="M49.8062 65.6739H49.2435C45.7518 65.7063 45.5063 65.5681 45.2108 62.033C44.861 57.8329 44.3526 56.2025 38.7838 56.4184C37.2954 56.4767 35.8049 56.4184 34.1145 56.4184V65.594H30.4708V53.298H39.0793C39.7311 53.298 40.3612 53.285 41.0043 53.298C45.0957 53.3606 46.0647 49.5664 45.0153 46.9405C44.3852 45.3662 42.886 44.9689 41.3411 44.9516C37.7626 44.9106 34.1819 44.9386 30.4773 44.9386V42.0838C34.9293 42.0838 39.4052 41.8679 43.8463 42.1637C46.6709 42.3538 48.6525 44.6989 48.9371 47.1435C49.4042 51.1385 48.5199 52.9741 45.1195 54.7513C48.8263 55.8807 48.3787 59.0702 48.9219 61.7912C49.1761 63.0609 49.4911 64.3091 49.8062 65.6739Z" fill="#E7B68E"/>
                <path d="M165.567 44.9365V41.7577C166.436 41.7577 167.266 41.7383 168.098 41.7577C171.672 41.8463 175.268 41.8031 178.818 42.0925C181.328 42.2955 183.303 43.7164 183.922 46.2387C184.507 48.6141 184.685 51.1191 182.784 53.1317C182.182 53.7795 181.334 54.179 180.467 54.7837C183.944 55.7684 183.618 58.8154 184.131 61.437C184.4 62.8169 184.702 64.1904 185 65.6264C185.095 65.5789 185 65.6567 184.926 65.6567C180.726 65.6675 181.18 66.3887 180.324 60.8669C179.77 57.293 178.873 56.4163 175.27 56.4163H169.404V65.5487H165.619V53.2613H169.215C171.729 53.2613 174.247 53.3476 176.754 53.2354C179.481 53.1144 180.726 51.4905 180.619 48.4845C180.543 46.2883 179.088 44.9754 176.574 44.9516C172.95 44.9214 169.33 44.9365 165.567 44.9365Z" fill="#E7B68E"/>
                <path d="M58.3321 56.4573V65.5659H54.797V53.3001H63.6293C64.2811 53.3001 64.9134 53.2721 65.5544 53.2786C69.5544 53.3196 70.4235 49.236 69.3654 46.9319C68.6527 45.3835 67.2079 44.9646 65.6543 44.9473C62.078 44.9084 58.4994 44.9343 54.7905 44.9343V42.0687C59.249 42.0687 63.6771 41.8528 68.0726 42.1421C70.9667 42.3279 73.0526 44.8199 73.3481 47.5106C73.7392 51.0824 72.5833 53.2829 69.4002 54.7189C72.5159 55.7036 72.7897 58.2065 73.0808 60.7654C73.2633 62.3678 73.7001 63.942 74.0368 65.6027C74.1281 65.5508 74.0499 65.6351 73.9673 65.6394C70.1302 65.7949 69.8021 65.4925 69.5066 61.6595C69.4524 60.9755 69.3522 60.2959 69.2068 59.6252C68.7875 57.6407 67.9031 56.6279 65.9477 56.4918C63.4729 56.3256 60.9655 56.4573 58.3321 56.4573Z" fill="#E7B68E"/>
                <path d="M59.3685 0.632729H76.1683V3.42493H62.8906V10.4173H75.3991V13.3563H62.9123V20.8951H76.7463V23.7844H59.3642L59.3685 0.632729Z" fill="#E7B68E"/>
                <path d="M4.56497 41.7491H8.16306C8.16306 46.7159 8.14785 51.5963 8.1761 56.4746C8.1958 57.31 8.29621 58.1416 8.47594 58.958C8.91049 61.0807 10.3206 62.2641 12.3869 62.6096C13.4903 62.8055 14.6157 62.8476 15.7308 62.7349C18.8769 62.3764 20.5739 60.5495 20.6456 57.3016C20.7455 52.7322 20.6912 48.1584 20.7042 43.589V41.7599H24.2806V46.8887C24.2806 50.1322 24.2806 53.3714 24.2806 56.6063C24.2806 62.4844 20.7586 65.9633 14.8595 65.9892C12.2674 66.0022 9.77525 65.7257 7.65898 64.0457C5.75782 62.534 4.64102 60.5171 4.59322 58.1417C4.48892 52.7365 4.56497 47.327 4.56497 41.7491Z" fill="#E7B68E"/>
                <path d="M87.986 23.8212H84.4096V0.777406C87.3081 0.0842154 87.8165 0.287209 89.4787 2.70366C94.2587 9.65717 99.0453 16.6107 103.838 23.5642C101.524 24.3805 100.362 24.0306 99.0584 22.154C95.8238 17.4809 92.5893 12.8078 89.3548 8.13473C89.0028 7.6251 88.6139 7.13922 87.986 6.29487V23.8212Z" fill="#E7B68E"/>
                <path d="M40.2069 3.53074H32.385V0.662956H51.4575V3.47027H43.8528V23.7693H40.2026L40.2069 3.53074Z" fill="#E7B68E"   className={path2ClassName} style={{transitionDuration: '500ms', transitionProperty: 'all'}}/>
                <path d="M84.4965 65.6113H80.9853V41.8808H84.4965V65.6113Z" fill="#E7B68E" className={path2ClassName} style={{transitionDuration: '1000ms', transitionProperty: 'all'}}/>
                <path d="M124.493 41.8592C127.906 41.5418 127.919 41.5375 129.11 44.5845C131.688 51.181 134.258 57.781 136.819 64.3847C136.969 64.7713 137.053 65.1816 137.173 65.594C133.401 65.8683 133.405 65.8661 132.149 62.5038C129.726 56.0254 127.3 49.547 124.871 43.0685C124.725 42.6734 124.621 42.2695 124.493 41.8592Z" fill="#E7B68E" className={path2ClassName} style={{transitionDuration: '500ms', transitionProperty: 'all'}}/>
                <path d="M141.473 23.7737C137.755 24.13 137.747 24.1321 136.541 20.9296C134.009 14.2079 131.483 7.48906 128.964 0.773093C129.142 0.671598 129.225 0.583061 129.312 0.578742C132.397 0.42326 132.393 0.425419 133.542 3.38606C136.163 10.1279 138.787 16.8698 141.473 23.7737Z" fill="#E7B68E"/>
                <path d="M191.963 41.8333H195.29V65.6005H191.963V41.8333Z" fill="#E7B68E" className={path2ClassName} style={{transitionDuration: '1000ms', transitionProperty: 'all'}}/>
                <path d="M114.359 23.8082H111V0.684555H114.359V23.8082Z" fill="#E7B68E" className={path2ClassName} style={{transitionDuration: '1000ms', transitionProperty: 'all'}}/>
                <path d="M114.596 65.6739C116.171 61.6292 117.681 57.7443 119.193 53.8594C120.699 53.6737 122.2 53.4275 123.71 53.3433C124.145 53.3217 124.797 53.6975 125.038 54.0797C125.455 54.7275 125.638 55.5179 125.957 56.3515C125.414 56.3774 125.003 56.4119 124.593 56.4163C121.831 56.4357 121.835 56.4357 120.938 58.932C120.297 60.7136 119.53 62.4585 119.033 64.2767C118.715 65.4407 118.163 65.8143 117.034 65.689C116.299 65.6091 115.55 65.6739 114.596 65.6739Z" fill="#E7B68E"/>
                <path d="M130.487 14.7341C130.273 14.7958 130.056 14.8448 129.836 14.8809C126.346 14.9975 126.342 14.9954 125.164 18.1936C124.671 19.5324 124.102 20.854 123.739 22.2275C123.376 23.6009 122.652 24.1386 121.24 23.9032C120.671 23.8082 120.073 23.8859 119.267 23.8859C120.571 20.4848 121.822 17.211 123.113 13.9524C123.413 13.1987 123.784 11.9743 124.282 11.8901C125.835 11.6266 127.541 11.5294 129.029 11.9397C129.696 12.1233 129.975 13.6759 130.487 14.7341Z" fill="#E7B68E"/>
                <path d="M100.145 10.02V0.652161H103.732V10.0221L100.145 10.02Z" fill="#E7B68E"/>
                </g>
                <defs>
                <clipPath id="clip0_13_144">
                    <rect width="205" height="66" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            </div>
        </div>
    )
}
export default AnimationHome;