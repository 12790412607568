import React, { useEffect, useRef, useState } from 'react'
import {ReactComponent as BottomAdd } from "../../assets/images/user/add.svg"
import post from '../../helpers/post'
import { USER_ENDPOINTS } from '../../services/api.config'

const SaveButton=({handle,evento})=>{

    return (
        <button onClick={handle} className='mr-4 px-10 py-3 rounded-xl bg-green font-bold'  style={{pointerEvents : evento}} >
            Confirmar
        </button>
    )
}
const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='ml-4 text-textColor font-bold'>
            Cancelar
        </button>
    )
}
const ModalSearcher = ({title,closeByButton,udateFamily}) => {
    let $family = useRef(null)
    let $names = useRef(null)
    let [family, setFamily] = useState("")
    let [names, setName] = useState("")
    let [number, seNumber] = useState()
    let [Error, setError] = useState("")
    let [invitado, setInvitado] = useState([]);
    let [evento, setEvento] = useState("all");
    useEffect(()=>{
        seNumber(2)
    },[])
    const addInput = () => {
        let inputCreater = document.createElement('input')
        inputCreater.type = 'text'
        inputCreater.placeholder = `Invitado ${number}`
        inputCreater.className = `rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80 prueba1000`
        document.getElementById('divAdd').appendChild(inputCreater)
        seNumber(number+1)
    }

    const clean = () => {
        document.getElementById('divAdd').innerHTML=''
        seNumber(2)
        setName('')
        setFamily('')
        $names.current.value = ''
        $family.current.value = ''
    }
    const handleCancel = ()=>{
        closeByButton()
        clean()
        setEvento('all')
    }
    const handleCreater = ()=>{
        setEvento('none')
        setInvitado([])
        if(family.length > 0 && names.length > 0){
            let inputData = document.getElementsByClassName("prueba1000")
            for(let el of inputData) {
                if(el.value.length > 0 ){
                    invitado.push(el.value)
                }
            }
            let _data={
                family : family,
                guest : invitado,
                }
            let _createClientPromise=post(USER_ENDPOINTS.create(),_data)
            _createClientPromise.then(response=>{
                if(response.message==="all is fine"){
                    udateFamily()
                    handleCancel()
                }
            })
        }else{
            setError("Los campos Familia y invitado 1 son obligatorio")
            setEvento('all')
        }
    }
    const preventBubbling=(e)=>e.stopPropagation()

    return (
        <div className='m-auto w-fit h-fit bg-white py-12 px-16 rounded-3xl relative' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-montserrat font-bold text-3xl'>{title}</h3>
                <p className=' font-poppins text-lg text-[red] text-center mt-4'>{Error}</p>
                <div className='flex h-[40vh] overflow-auto flex-row px-8 w-[50rem] flex-wrap my-8 justify-between content-start mr-14'>
                    <input type="text" placeholder="Familia (Obligatorio)" ref={$family} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setFamily(e.target.value)}}/>
                    <input type="text" placeholder="Invitado 1 (Obligatorio)" ref={$names} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80 prueba1000" onChange={(e)=>{setName(e.target.value)}}/>
                    <div className='flex flex-row flex-wrap justify-between  content-start w-full' id="divAdd"></div>
                </div>
            </div>
            <div className='flex justify-around my-5'>
                <CancelButton handle={handleCancel}/>
                <SaveButton handle={handleCreater} evento={evento}/>
            </div>
            <div className='h-full right-12 top-0 absolute flex '>
                    <BottomAdd className="h-auto w-10 my-auto cursor-pointer" onClick={addInput}/>
            </div>
        </div>
    )
}

export default ModalSearcher
































// const ModalSearcher = ({title,closeByButton,setClients}) => {
    //     let $family = useRef(null)
    //     let $name1 = useRef(null)
    //     let $name2 = useRef(null)
    //     let $name3 = useRef(null)
    //     let $name4 = useRef(null)
    //     let $name5 = useRef(null)
    //     let $name6 = useRef(null)
    //     let $name7 = useRef(null)
    //     let $name8 = useRef(null)
    //     let $name9 = useRef(null)
    //     let $name10 = useRef(null)
    //     let $name11 = useRef(null)
    //     let [family, setFamily] = useState()
    //     let [name1, setName1] = useState()
    //     let [name2, setName2] = useState()
    //     let [name3, setName3] = useState()
    //     let [name4, setName4] = useState()
    //     let [name5, setName5] = useState()
    //     let [name6, setName6] = useState()
    //     let [name7, setName7] = useState()
    //     let [name8, setName8] = useState()
    //     let [name9, setName9] = useState()
    //     let [name10, setName10] = useState()
    //     let [name11, setName11] = useState()
    
    //     const handleCreater = ()=>{
    //         if(family.length > 0 && name1.length > 0){
    //             // Post
    //             let _data={
    //                 family:family,
    //                 name1 : name1,
    //                 name2 : name2,
    //                 name3 : name3,
    //                 name4 : name4,
    //                 name5 : name5,
    //                 name6 : name6,
    //                 name7 : name7,
    //                 name8 : name8,
    //                 name9 : name9,
    //                 name10 : name10,
    //                 name11 : name11,
    //             }
    //             let _createClientPromise=post(USER_ENDPOINTS.create(),_data)
    //             _createClientPromise
    //             .then(response=>{
    //                 if(response.message==="all is fine"){
    //                     setFamily("")
    //                     setName("")
    //                     setName("")
    //                     $name.current.value = ''
    //                     $table.current.value = ''
    //                     let getGuest = get(USER_ENDPOINTS.getAll())
    //                     getGuest.then((response) => {
    //                         setClients(response.data);
    //                     })
    //                 }
    //             })
    //         }
    //     }
    //     const preventBubbling=(e)=>e.stopPropagation()
    
    //     return (
    //         <div className='m-auto w-fit h-fit bg-white py-12 px-16 rounded-3xl' onClick={(e)=>preventBubbling(e)}>
    //             <div className='flex w-full flex-col justify-center'>
    //                 <h3 className='w-fit m-auto font-montserrat font-bold text-3xl'>{title}</h3>
    //                 <div className='flex h-[40vh] overflow-auto flex-row px-10 w-[50rem] flex-wrap my- justify-around'>
    //                     <input type="text" placeholder="Familia (Obligatorio)" ref={$family} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setFamily(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 1 (Obligatorio)" ref={$name1} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName1(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 2" ref={$name2} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName2(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 3" ref={$name3} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName3(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 4" ref={$name4} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName4(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 5" ref={$name5} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName5(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 6" ref={$name6} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName6(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 7" ref={$name7} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName7(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 8" ref={$name8} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName8(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 9" ref={$name9} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName9(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 10" ref={$name10} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName10(e.target.value)}}/>
    //                     <input type="text" placeholder="Invitado 11" ref={$name11} className="rounded-xl border-solid shadow mt-5 mb-5 h-fit text-jn px-3 py-3 w-80" onChange={(e)=>{setName11(e.target.value)}}/>
    //                 </div>
    //             </div>
    //             <div className='flex justify-around my-5'>
    //                 <CancelButton handle={closeByButton}/>
    //                 <SaveButton handle={handleCreater}/>
    //             </div>
    //         </div>
    //     )
    // }
    
    // export default ModalSearcher