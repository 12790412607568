import React from 'react'


const SaveButton=({handle})=>{

    return (
        <button onClick={handle} className='mr-4 px-10 py-3 rounded-xl bg-green font-bold'>
            Confirmar
        </button>
    )
}
const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='ml-4 px-10 py-3 text-red font-bold'>
            Cancelar
        </button>
    )
}

const ModalAlert = ({title,message,closeByButton,handleSave,handleCancelation}) => {
    const preventBubbling=(e)=>e.stopPropagation()
  return (
    <div className='m-auto w-1/4 h-fit bg-white py-10 rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-montserrat font-bold text-3xl'>{title}</h3>
                <p className='font-montserrat text-lg my-12 mx-10 text-center'>{message}</p>
            </div>
            <div className='flex justify-between my-5 mx-10'>
                <CancelButton handle={handleCancelation}/>
                <SaveButton handle={handleSave}/>
            </div>
        </div>
  )
}


{/* <div className='modal-alert'>
<span className='modal-alert-tittle'>
    <h3>{title}</h3>
</span>
<button className='modal-alert-close' onClick={closeByButton}></button>
<p className='modal-alert-message'>{message}</p>
<div className='modal-alert-actions'>
    <SaveButton handle={handleSave}/>
    <CancelButton handle={handleCancelation}/>
</div>
</div> */}

export default ModalAlert