import {ReactComponent as LogoutSvg } from "../../assets/images/user/log-out-hollow-dark.svg"
import {ReactComponent as BottomAdd } from "../../assets/images/user/add.svg"
import {ReactComponent as Not } from "../../assets/images/user/not.svg"
import {ReactComponent as Yes } from "../../assets/images/user/yes.svg"
import {ReactComponent as Copy } from "../../assets/images/user/copy-filled.svg"
import {ReactComponent as Delete } from "../../assets/images/user/trash-filled-red.svg"
import {ReactComponent as Arrow } from "../../assets/images/arrow.svg"
import {ReactComponent as MusicFile } from "../../assets/images/musicFile.svg"
import {ReactComponent as Edict } from "../../assets/images/edit.svg"
import ESTranslation from "../../ES/ESTranslation"
import Table from "../../components/container/table"
import { LOGIN_ENDPOINTS, USER_ENDPOINTS } from "../../services/api.config"
import get from "../../helpers/get"
import { UserAuthContext } from "../../contexts/authContext"
import ModalBackground from "../../components/modal/modal_background"
import ModalSearcher from "../../components/modal/modal_new"
import useModal from "../../hooks/useModal"
import { useEffect, useRef, useState } from "react"
import useFetch from "../../hooks/useFetch"
import post from "../../helpers/post"
import put from "../../helpers/put"
// import ModalAlert from "../../components/modal/modal_alert"
import adelete from "../../helpers/adelete"
import ModalMessage from "../../components/modal/modal_music_view"
import ModalMusic from "../../components/modal/modal_music"
import ModalMusicView from "../../components/modal/modal_music_view"
import ModalAlert from "../../components/modal/modal_alert"
import { Link, Navigate, useParams } from "react-router-dom"
import { USER } from "../../routes/paths"
import ModalGuest from "../../components/modal/modal_guest"
import ModalEdict from "../../components/modal/modal_Edict"

const preprocessTableBodyData=(data,setConf,setDene,setEspe,setClients,handleDelete,handleEdict,handleMessage,setName)=>{
    
    if(!data){
        return null
    }
    let conf = 0
    let dene = 0
    let espe = 0
    let classN = "w-fit m-auto font-bold"
    setName(data.family)
    let DataArray = data.guest
    DataArray=DataArray || []
    const actionButtons=(id,name)=>{
        return (
            <>
                <div className="flex justify-center">
                    <Edict className="m-auto cursor-pointer h-6 w-auto" onClick={()=>{handleEdict(id,name,false)}}/>
                </div>
            </>
        )
    }
    const getStatus = (data)=>{
        if(data === 1){
            espe++
            return(
                <p className={`text-[#b5b526] ${classN}`}>En espera</p>
            )
        }
        if(data === 2){
            conf++
            return(
                <p className={`text-green ${classN}`}>Confirmado</p>
            )
        }
    }
    const htmlNumber= (data)=>{
        return(
            <p className="w-fit h-fit m-auto">{data}</p>
        )
    }
    const htmlLink= (data)=>{
        const handleCopy = (e, link) => {
            navigator.clipboard.writeText(`https://leoyfranye.maikdev.com/?link=${link}`)
            // e.clipboardData.setData("Text", `https://leoyfranye.maikdev.com/?link=${link}`)
          } 
        //   <p className="p-2 rounded-lg w-fit m-auto bg-primary-m50 cursor-pointer" onClick={(e)=>{handleCopy(e,data)}}>copiar</p>
        return(
            <Copy className="rounded-lg m-auto fill-primary-m50 cursor-pointer w-auto h-8" onClick={(e)=>{handleCopy(e,data)}}/>
            
        )
    }
    const deleteButton= (id,name)=>{
        return (
            <>
                <div className="flex justify-center">
                    <Delete className="m-auto cursor-pointer h-6 w-auto" onClick={()=>{handleDelete(id,name,false)}}/>
                </div>
            </>
        )
    }
    const htmlName=(name)=>{
        return (
            <>
                <div className="flex justify-center">
                    <p className="font-bold">{name}</p>
                </div>
            </>
        )
    }
    let _processedData=[...DataArray].map(row=>{
        let {id,name,status}=row
            // let  _slug=replaceEmptySpaces
            let _newData={
                Delete : deleteButton(id,name),
                Name: htmlName(name),
                // htmlNumber: htmlNumber(number),
                Statu: getStatus(status),
                actions:actionButtons(id,name)
            }
            return _newData
    })
    setConf(conf)
    setDene(dene)
    setEspe(espe)
    return _processedData
        
}


const UserFamily = ()=>{
    const {id}=useParams();
    let [name, setName] = useState("")
    let [table, setTable] = useState(0)
    let [conf, setConf] = useState(0)
    let [dene, setDene] = useState(0)
    let [espe, setEspe] = useState(0)
    let [deleteName, setDeleteName] = useState()
    let [deleteId, setDeleteId] = useState()
    let [title, setTitle] = useState()
    let [messageName, setMessageName] = useState()
    let $name = useRef(null)
    let [isActiveModal,activateModal,desactivateModal]=useModal(false)
    let [isActiveDelete,activateDelete,desactivateDelete]=useModal(false)
    let [isActiveMessage,activateMessage,desactivateMessage]=useModal(false)
    let textBottom = 'w-fit px-7 py-1 my-auto font-montserrat text-lg rounded-lg mx-4 font-bold'
    const {logout} = UserAuthContext();
    let {data:fetchedClients,loading:loadingFetchedClients,error:fetchedClientsErrors}=useFetch(USER_ENDPOINTS.getOneFamily(id))
    let [processedClients,setProcessedClients]=useState(null)
    let [clients,setClients]=useState(null)
    let [deleteType,setDeleteType]=useState(true)
    let [numbre, Setumbre] = useState(1)
    let [numbre2, Setumbre2] = useState(1)
    let [redi, setRedi] = useState("hola")
    let [textEdit, setTextEdit] = useState()
    let [editType,setEditType]=useState(true)
    let [editId, setEditId] = useState()

    useEffect(()=>{
        Setumbre(numbre+1)
        if(numbre === 2){
            if(fetchedClients)setClients(fetchedClients.data)
        }
    },[fetchedClients])

    useEffect(()=>{
        Setumbre2(numbre2+1)
        if(numbre2 >= 2){
            let _processedData=preprocessTableBodyData(clients,setConf,setDene,setEspe,setClients,handleDelete,handleEdict,handleMessage,setName)
            if(!_processedData){
                setRedi()
            }
            setProcessedClients(_processedData)
        }
    },[clients])
    
    const udateFamily=()=>{
        let getGuest = get(USER_ENDPOINTS.getOneFamily(id))
            getGuest.then((response) => {
                setClients(response.data);
            })
    }
    if(!redi){
        return <Navigate to={USER}/>
    }
    const handleDelete=(id, name, family)=>{
        family?setDeleteName(`¿Estas seguro/a que deseas ELIMINAR la familia  ${name}?`) :  setDeleteName(`¿Estas seguro/a que deseas ELIMINAR el invitado ${name}?`) 
        setDeleteId(id)
        setDeleteType(family)
        activateDelete()
    }
    const handleMessage=(dataMessage, name)=>{
        setMessageName(name)
        activateMessage()
    }
    const handleEdict=(id, name, type)=>{
        type?setTitle(`Edita el nombre de la familia`) :  setTitle(`Edita el nombre de el invitado`) 
        setEditId(id)
        setEditType(type)
        $name.current.value = name
        activateMessage()
    }
    const handleEdictFamily=()=>{
        if(editType){
            let _data={
                family : textEdit,
                }
            let _deleteClientPromise=put(USER_ENDPOINTS.updateFamilyUser(editId),_data)
            _deleteClientPromise
            .then(response=>{
                udateFamily()
                desactivateMessage()
            })
            .catch(err=>{
            })
            .finally(()=>{
                desactivateDelete()
            })
        }else{
            let _data={
                name : textEdit,
                }
            let _deleteClientPromise=put(USER_ENDPOINTS.updateGuestUser(editId),_data)
            _deleteClientPromise
            .then(response=>{
                console.log(response)
                udateFamily()
                desactivateMessage()
            })
            .catch(err=>{
            })
            .finally(()=>{
                desactivateDelete()
            })
        }
    }
    const Logout = ()=>{
        get(LOGIN_ENDPOINTS.logout()).then(r =>{
            if(r.message === 'all is fine'){
                logout()
            }
        })
    }
    const handleDeleteFamily=()=>{
        if(deleteType){
            let _deleteClientPromise=adelete(USER_ENDPOINTS.deleteFamily(deleteId))
            _deleteClientPromise
            .then(response=>{
                setRedi()
            })
            .catch(err=>{
            })
            .finally(()=>{
                desactivateDelete()
            })
        }else{
            let _deleteClientPromise=adelete(USER_ENDPOINTS.deleteGuest(deleteId))
            _deleteClientPromise
            .then(response=>{
                udateFamily()
                desactivateDelete()
            })
            .catch(err=>{
            })
            .finally(()=>{
                desactivateDelete()
            })
        }
    }
    return(
        <>
            <div className='bg-primary min-h-screen flex flex-col'>
                <header className='w-full flex justify-between h-fit bg-white'>
                    <section className="w-fit h-24 my-auto ml-8 flex"><Link to={USER} className="flex"><Arrow className="w-auto h-12 m-auto"/></Link></section>
                    <section className="flex">
                    </section>
                    <section className="w-fit h-24 my-auto mr-8 flex"><LogoutSvg className="h-1/3 w-auto m-auto cursor-pointer" onClick={Logout}/></section>
                </header>
                <main className='flex flex-col justify-center items-center my-auto h-fit w-full'>
                    <section className="w-11/12 bg-white mx-auto flex text-center py-4 rounded-t-2xl">
                        <div className="w-4/12">
                            <h3 className=" font-poppins">Familia : <span className=" font-poppinsBold">{name}</span></h3>
                        </div>
                        <div className="flex justify-center w-8/12">
                            <div className="flex justify-center cursor-pointer border-r-2 border-textColor" onClick={activateModal}>
                                <h3 className=" font-poppinsBold mr-2 ml-6 my-auto">Agregar invitado</h3>
                                <BottomAdd className="w-6 h-auto my-auto fill-green stroke-green mr-6"/>
                            </div>
                            <div className="flex justify-center cursor-pointer border-r-2 border-textColor"  onClick={()=>{handleEdict(id,name,true)}}>
                                <h3 className=" font-poppinsBold mr-2 ml-6 my-auto">Editar Familia</h3>
                                <Edict className="w-7 h-auto my-auto fill-green stroke-green mr-6"/>
                            </div>
                            <div className="flex justify-center cursor-pointer"  onClick={()=>{handleDelete(id,name,true)}}>
                                <h3 className=" font-poppinsBold mr-2 ml-6 my-auto">Elimar Familia</h3>
                                <Delete className="my-auto h-6 w-auto mr-6"/>
                            </div>
                        </div>
                    </section>
                    <Table head={ESTranslation.TABLE_HEAD.family_page} columns="10% 50% 15% 25%" body={processedClients} error={fetchedClientsErrors} className='w-11/12 bg-[#efefef] mx-auto grid text-center py-4'/>
                </main>
                <footer className='w-full mt-auto'>
                    <span className='flex flex-col justify-center items-center'>
                        <p className=' text-secondary text-in'>©2024 Todos Los Derechos Reservados</p>
                    </span>
                </footer>
            </div>
            <ModalBackground isActive={isActiveDelete} closeByBackground={desactivateDelete}>
                <ModalAlert title={"¡Atención!"} message={deleteName} closeByButton={desactivateDelete} handleSave={handleDeleteFamily} handleCancelation={desactivateDelete}/>
            </ModalBackground>
            <ModalBackground isActive={isActiveModal} closeByBackground={desactivateModal}>
                <ModalGuest title={"Invitacion"} closeByButton={desactivateModal} setClients={setClients} udateFamily={udateFamily} idFamily={id}/>
            </ModalBackground>
            <ModalBackground isActive={isActiveMessage} closeByBackground={desactivateMessage}>
                <ModalEdict title={title} handleSave={handleEdictFamily} handleCancel={desactivateMessage} handleText={setTextEdit} $name={$name}/>
            </ModalBackground>
            {/* <ModalBackground isActive={isActiveModal} closeByBackground={desactivateModal}>
                <ModalSearcher title={"Invitacion"} closeByButton={desactivateModal} handleCreater={handleCreater} handleName={handleName} handleTable={handleTable} $name={$name} $table={$table}>
                </ModalSearcher>
            </ModalBackground>
            <ModalBackground isActive={isActiveMessage} closeByBackground={desactivateMessage}>
                <ModalMessage title={`Palabras de ${messageName}!`} message={message} closeByButton={desactivateMessage} handleSave={handleDeleteGuest} handleCancelation={desactivateMessage}/>
            </ModalBackground> * */}
        </>
    )
}
export default UserFamily